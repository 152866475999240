.hero{
background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://images.unsplash.com/photo-1620656798579-1984d9e87df7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80");
height: 600px;
background-color: white;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
display: flex;
justify-content: center;
align-items: center;
color: #fff;
font-size: 4rem;
margin-top: 6;
} 
