/* display: "flex", justifyContent: "space-around", mt: 2, mb: 2 */
.card{
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.box {
    display: flex;
    flex-direction: column;
}